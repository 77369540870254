<template>
  <dash-page-new
      :title="entity.translated_name || $t('Course_')"
      :root="$t('MyStudy')"
      :root-location="businessDashLink('')"
      :subtitle="$t('Course_')"
      icon="mdi-school-outline"
      :no-content-condition="!coursePermission.is_permitted"
      :loading="loading"
  >

    <template #no-content>
      <v-sheet
          :style="`border : 1px solid ${wsBACKGROUND}`"
          :class="!SM ? 'd-flex' : null"
          class="wsRoundedLight pa-6 mt-5  align-center justify-space-between" :color="wsLIGHTCARD">

        <div>
          <h5>
            <v-icon class="mr-1" :color="wsWARNING">mdi-alert-octagon</v-icon>
            {{ $t(coursePermission.error) }}
          </h5>
          <h5 v-if="coursePermission.days_for_start" class="text-center mt-3">
            <v-icon :color="wsACCENT">mdi-calendar</v-icon>
            {{ $t("DaysForEducationStart") }} : {{ coursePermission.days_for_start }}
          </h5>
        </div>


        <div  v-if="coursePermission.order_id && HAS_ONLINE_PAYMENT" class="d-flex justify-center">

          <view-button-course-enroll
              :display="triggerDisplayEnroll"
              :entity="entity"
              :student-profile="studentProfile"
              buy-course
              :order-id="coursePermission.order_id"

          >
            <v-btn @click="triggerDisplayEnroll++"
                   :color="wsATTENTION"
                   :class="SM ? 'mt-3' : null"
                   :block="SM"
                   class="noCaps"
                   elevation="0"
                   dark >
              <v-icon class="mr-2">mdi-basket-plus</v-icon>
              {{ $t('MakePayment') }} ({{ coursePermission.order_total }} {{ BUSINESS_CURRENCY }})
            </v-btn>
          </view-button-course-enroll>

        </div>

        <div  v-if="coursePermission.error === 'subscription_expired' "  >

          <view-button-course-enroll
              :display="triggerDisplayEnroll"
              :entity="entity"
              :student-profile="studentProfile"
              is-subscription-extend
              buy-course

          >
            <v-btn @click="triggerDisplayEnroll++"
                   :color="wsATTENTION"
                   :class="SM ? 'mt-3' : null"
                   :block="SM"
                   class="noCaps"
                   elevation="0"
                   dark >
              <v-icon class="mr-2">mdi-basket-plus</v-icon>
              {{ $t('ExtendSubscription') }}
            </v-btn>
          </view-button-course-enroll>


        </div>

        <div v-if="coursePermission.error === 'course_finished'">

          <view-button-course-enroll
              :display="triggerDisplayEnroll"
              :entity="entity"
              :student-profile="studentProfile"
          >
            <v-btn @click="triggerDisplayEnroll++"
                   :color="wsATTENTION"
                   :class="SM ? 'mt-3' : null"
                   :block="SM"
                   class="noCaps"
                   elevation="0"
                   dark >
              <v-icon class="mr-2">mdi-basket-plus</v-icon>
              {{ $t('Purchase') }}
            </v-btn>
          </view-button-course-enroll>

        </div>


      </v-sheet>
    </template>


    <template #default>

      <v-sheet
            v-if="entity.is_subscription"
            :style="`border : 1px solid ${wsBACKGROUND}`"
            :class="!SM ? 'd-flex' : null"
            class="wsRoundedLight pa-6 mt-5  align-center justify-space-between" :color="wsLIGHTCARD">

        <h5>
          <span :style="`color : ${wsACCENT}`">
            <v-icon :color="wsACCENT">mdi-calendar</v-icon>
             {{$t('SubscriptionTill') }}:
          </span>
          {{ PARSE_DATE(studentProfile.subscription_date) }}<br>

        </h5>

        <view-button-course-enroll
            :display="triggerDisplayEnroll"
            :entity="entity"
            :student-profile="studentProfile"
            is-subscription-extend
            buy-course
        >
          <v-btn @click="triggerDisplayEnroll++"
                 :color="wsATTENTION"
                 :class="SM ? 'mt-3' : null"
                 :block="SM"
                 class="noCaps"
                 elevation="0"
                 dark >
            <v-icon class="mr-2">mdi-basket-plus</v-icon>
            {{ $t('ExtendSubscription') }}
          </v-btn>
        </view-button-course-enroll>

      </v-sheet>

      <v-sheet
          v-else-if="coursePermission.education_end"
          :style="`border : 1px solid ${wsBACKGROUND}`"
          :class="!SM ? 'd-flex' : null"
          class="wsRoundedLight pa-6 mt-5  align-center justify-space-between" :color="wsLIGHTCARD">

        <h5>
          <span class="mr-2" :style="`color : ${wsACCENT}`">
            <v-icon :color="wsACCENT">mdi-calendar</v-icon>
             {{$t('ActiveTill') }}:
          </span>
          <span v-if="coursePermission">
              {{ PARSE_DATE(coursePermission.education_end) }}
          </span>

        </h5>

      </v-sheet>

      <v-sheet v-if="$store.state.MI_BROWSER_APP" :style="`border : 1px solid ${wsBACKGROUND}`"
               class="wsRoundedLight pa-6 mt-5   align-center " :color="wsLIGHTCARD"
      >
        <v-icon v-if="!SM" :color="wsATTENTION" class="mr-3">mdi-information</v-icon>
        <h5 class="wsACCENT">{{ $t('BadUserAgentWarning') }}</h5>

      </v-sheet>


    </template>

    <template #defaultDesktop>
        <v-sheet v-for="(module,i) in entity.modules" :key="i + 'semester'" :ref="'module_' + i" class="wsRounded mt-3 pb-9">
          <!-- Semester Content-->
          <v-simple-table dense style="border-radius: 15px; !important  " class="wsRoundedHalfTop" >

            <!-- Header with semester info -->
            <thead  >
            <tr >

              <!-- Semester Info -->
              <td class="py-2 wsRoundedHalfTop"
                  :style="`border-bottom: 1px solid ${wsBACKGROUND} `"  >
                <!-- Semester Number -->
                <div class="d-flex align-center justify-space-between">

                  <div class="d-flex align-center">
                    <h3 class="text-no-wrap"> {{ module.name }}</h3>
                  </div>

                  <div v-if="module.date_start || module.date_end" class="d-flex align-center ">
                    <v-icon size="18" :color="wsACCENT" class="mr-1"> mdi-calendar </v-icon>
                    <h5>
                      {{ module.date_start ? getComponentDateText(module.date_start) : $t('FromBeginning') }}
                      <span >-</span>
                      <span >
                    {{ module.date_end ? getComponentDateText(module.date_end) : $t('TillTheEnd') }}
                  </span>
                    </h5>
                  </div>

                </div>

              </td>

            </tr>
            </thead>
            <!-- :: MODULE CONTENT :: -->
            <tbody>
            <!-- Components content -->
            <template  v-for="(topic,j) in module.topics">

              <!-- TOPIC HEADER  -->
              <!-- Group Name  -->
              <tr style="background: transparent" :key="j + 'topic_name'">
                <td  :style="`border-bottom: 1px solid ${wsBACKGROUND} `" colspan="7">
                  <h4 class="py-3">{{  topic.name  }}</h4>
                </td>
              </tr>

              <!--  TOPIC COMPONENTS  -->
              <tr :key="j + 'components'"
                  style="background-color: transparent;" >
                <!-- Components content  -->
                <td colspan="7" class="pt-3" style="border: none">
                  <table width="100%" class="componentsTable"    >
                    <!-- Components list  -->
                    <tbody>


                    <tr  v-for="(component, k) in topic.components" :key="'component' + i + j + k "
                         @contextmenu="openContextMenu($event, component)"
                         @click="displayEntity(component, k , j , i)"
                         :style="!coursePermission.is_permitted || !component.is_permitted ? 'color : grey' : 'cursor : pointer'" >

                      <!--  Name -->
                      <td  class="py-1 pl-3">

                        <router-link
                            :is="getEntityLink(component, k , j , i) && !['file','video'].includes(component.entity_type)  && 'componen' && displayEntityCondition(component) ? 'router-link' : 'span'"
                            :to="getEntityLink(component, k , j , i)"
                            class="noUnderline"
                        >
                          <div class="d-flex align-start">
                            <v-icon  :color="getEntityTypeIconColor(component)" class="mr-3 "> {{ getEntityTypeIcon(component ) }}</v-icon>
                            <h4 style="color : black" v-html="component.name"></h4>
                          </div>

                        </router-link>


                      </td>
                      <td>
                      </td>

                      <td  width="70px" class="text-no-wrap  pr-3" align="right">
                        <div v-if="component.date_start || component.date_end" class="d-flex align-center ">
                          <v-icon size="18" :color="wsDARK" class="mr-1"> mdi-calendar </v-icon>
                          <h5 class="" >
                            {{ component.date_start ? getComponentDateText(component.date_start) : $t('FromBeginning') }}
                            <span v-if="component.entity_type !== 'task_video'" >-</span>
                            <span v-if="component.entity_type !== 'task_video'" >
                          {{ component.date_end ? getComponentDateText(component.date_end) : $t('TillTheEnd') }}
                        </span>
                          </h5>
                          <h5  v-if="component.entity_type === 'task_video' && component.video_time" class="font-weight-light ml-3" >
                            <v-icon size="18" :color="wsDARK" class="mr-1"> mdi-clock </v-icon>
                            {{ component.video_time }}
                          </h5>
                        </div>

                      </td>
                      <td width="10px" align="right">
                        <div class="d-flex justify-end align-center">
                          <v-chip v-if="component.result"   :color="wsACCENT" class="mr-3" dark>
                            <h4  class=" text-no-wrap d-flex align-center">
                              <v-icon  class="mr-1">mdi-star</v-icon>
                              {{ component.result }}
                            </h4>
                          </v-chip>

                          <v-icon :color="component.passed ? 'green lighten-1' : ''" >{{ component.passed ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
                        </div>
                        </td>

                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

            </template>
            </tbody>
          </v-simple-table>
        </v-sheet>
    </template>

    <template #defaultMobile>

      <h4 :style="`color : ${wsACCENT}`" class="text-center  my-3"> {{ entity.translated_name }}</h4>


      <v-divider :style="`border-color : ${wsBACKGROUND}`" />
        <div v-for="(module,i) in entity.modules"
             :key="i + 'semester'" :ref="'module_' + i">

          <div v-if="module.date_start || module.date_end" class="d-flex justify-center align-center mt-3">
            <v-icon size="18" :color="wsACCENT" class="mr-1"> mdi-calendar </v-icon>
            <h5>
              {{ module.date_start ? getComponentDateText(module.date_start) : $t('FromBeginning') }}
              <span >-</span>
              <span >
                    {{ module.date_end ? getComponentDateText(module.date_end) : $t('TillTheEnd') }}
                  </span>
            </h5>
          </div>

          <h4 :style="`color : ${wsACCENT}`" style="line-height: 1.2" class="text-center  my-3 px-1">
            {{ module.name }}
          </h4>



          <v-divider :style="`border-color : ${wsBACKGROUND}`" />
          <v-sheet  class="wsRounded "  v-for="(topic,j) in module.topics"  :key="j + 'topic_name'">
            <h4 class="text-center px-1  py-3">{{  topic.name  }}</h4>
            <v-divider :style="`border-color : ${wsBACKGROUND}`" />

            <div  v-for="(component, k) in topic.components" :key="'component' + i + j + k "
                  @click="displayEntity(component , k , j , i)"
                  :style="!coursePermission.is_permitted || !component.is_permitted ? 'color : grey' : 'cursor : pointer'" >


              <div v-if="component.date_start || component.date_end" class="d-flex  mt-3 align-center ">
                <v-icon size="18" :color="wsDARK" class="mr-1"> mdi-calendar </v-icon>
                <h5 class="" >
                  {{ component.date_start ? getComponentDateText(component.date_start) : $t('FromBeginning') }}
                  <span v-if="component.entity_type !== 'task_video'" >-</span>
                  <span v-if="component.entity_type !== 'task_video'" >
                          {{ component.date_end ? getComponentDateText(component.date_end) : $t('TillTheEnd') }}
                        </span>
                </h5>
                <h5  v-if="component.entity_type === 'task_video' && component.video_time" class="font-weight-light ml-3" >
                  <v-icon size="18" :color="wsDARK" class="mr-1"> mdi-clock </v-icon>
                  {{ component.video_time }}
                </h5>
              </div>

              <div class="d-flex align-start justify-space-between">

                <router-link
                    :is="getEntityLink(component, k , j , i) && !['file','video'].includes(component.entity_type)  && displayEntityCondition(component) ? 'router-link' : 'span'"
                    :to="getEntityLink(component, k , j , i)"
                    class="noUnderline"
                >
                  <div class="d-flex align-start  py-3">
                    <v-icon  :color="getEntityTypeIconColor(component)" class="mr-1 "> {{ getEntityTypeIcon(component ) }}</v-icon>
                    <h4 style="color : black"  v-html="component.name"></h4>
                  </div>
                </router-link>

                <v-icon class="mt-3" :color="component.passed ? 'green lighten-1' : ''" >{{ component.passed ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>

              </div>



              <v-divider :style="`border-color : ${wsBACKGROUND}`" />

            </div>

          </v-sheet>




        </div>
    </template>

    <template #dialog>

      <!-- Context menu-->
      <ft-select :expanded="displayContextMenu"
                 @input="contextAction($event)"
                 :x="x" :y="y"
                 :items="editCourseActionsSelect"
                 absolute
      />


      <subscription-dialog
          v-if="displaySubscriptionDialog"
          v-model="displaySubscriptionDialog"
          :course="entity"
          :student-profile="studentProfile"
      />

      <!-- Display Video Dialog-->



      <!-- Display Photo Dialog-->
      <v-dialog v-model="displayImage"
                 fullscreen>
        <v-sheet dark  class="fill-height">
          <div class="d-flex justify-space-between py-2 px-3 align-center">
            <h6>{{ selectedImage.name }}</h6>
            <div>
              <v-btn v-if="!SM" small :href="selectedImage.src" class="noCaps mr-3"  outlined download type="attachment" target="_self">
                <v-icon small>mdi-download</v-icon>
                {{$t('Download')}}
              </v-btn>
              <v-btn :small="SM" icon @click="displayImage = false"><v-icon :small="SM" >mdi-close</v-icon></v-btn>
            </div>
          </div>
          <v-btn v-if="SM" block small :href="selectedImage.src" class="noCaps mb-3"  text download type="attachment" target="_self">
            <v-icon small>mdi-download</v-icon>
            {{$t('Download')}}
          </v-btn>
          <v-img transition="xxx" :src="selectedImage.src" />
        </v-sheet>
      </v-dialog>

      <ws-pdf-dialog
          v-model="displayPdf"
          :file="selectedComponent.file"
          :course-id="selectedComponent.course_id"
          student
      />


    </template>

  </dash-page-new>
</template>

<script>


import {mapActions} from "vuex";
import subscriptionDialog from "@/components/pages/westudy/UI/subscriptionDialog";

import viewButtonCourseEnroll from "@/components/AvalonEditor/View/elements/button/viewButtonCourseEnroll";
export default {
  name: "PageCourses",
  components : {
    subscriptionDialog,
    viewButtonCourseEnroll
  },

  props : ['uuid'],
  data() {
    return {
      triggerDisplayEnroll : 0,
      selectedComponent : {},
      displayPdf : false,
      //context menu
      displayContextMenu: false,
      x: 0,
      y: 0,

      displaySubscriptionDialog : false,
      selectedVideoName : '',
      displayImage : false,
      selectedImage : {},
      studentProfile : {},

      config: {
        id: 'vs',
        url: '/xgplayer-demo.mp4',
        aspect : '1',
        width : '100%',
        height : '100%',
      },
      Player: null,

      // apex chart
      courseCompletion : 0,
      displayVideo : false,
      videoSource : null,
        //
      loading : false,

      coursePermission : {},
      courseNotStarted : false,
      courseNotPurchased : false,
      educationStart : "",

      items : [],

      selectedTopic : null,
      selectedModule : null,

      editNameModule : false,


      newEntity  : true,
      displayDialog : false,
      displayTopicDialog : false,
      displayModuleDialog : false,
      entity : {
        name : "",
        components : []
      },
      entityData : {
        name : {},
      },
      entityDataDefault : {
        name : { },
        alias : ''
      },
      defaultComponent : {
        name : { },
        topic_id : null,
      },

      fileUploaded : false,
      selectedFile : {},
      redirect : false,
    }
  },
  computed : {

    editCourseActionsSelect() {
      return [
        { text : this.$t('OpenInNewWindow') , value : 'open_in_new' , icon : 'mdi-open-in-new' }
      ]
    },

    showEntityTypesSelectCondition() {
      return !( this.entityData.file || this.entityData.lecture || this.entityData.task  )
    },
    entityTypes() {
      return [
        { text : this.$t('None'), value: null },
        { text : this.$t('Lecture'), value: 'lecture' },
        { text : this.$t('Task'), value: 'task' },
        { text : this.$t('File'), value: 'file' },
      ]
    },

  },
  methods : {
    ...mapActions('courses', [
        'GET_STUDENT_COURSE',
        'EDIT_COMPONENT_RESULT',
    ]),
    ...mapActions('upload', [
      'GET_STUDENT_COMPONENT_FILE'
    ]),
    ...mapActions('liqpay', ['GET_BUSINESS_BUTTON']),
    ...mapActions('monobank', ['GET_MONO_CHECKOUT_URL']),
    ...mapActions('fondy', ['GET_FONDY_CHECKOUT_URL']),
    ...mapActions('wayforpay', ['GET_WAYFORPAY_CHECKOUT_URL']),


    contextAction(action) {

      if ( action === 'open_in_new' ) {
        if ( this.getEntityDisplayCondition(this.selectedComponent) )  {
          let route = this.$router.resolve(this.getEntityLink(this.selectedComponent))
          window.open(route.href, '_blank');
        }
      }

    },
    openContextMenu($event, component) {
      if ( !['file','video'].includes(component.entity_type) ) {
        return
      }
      this.selectedComponent = Object.assign({} , component)
      $event.preventDefault()
      this.displayContextMenu = false
      this.x = $event.clientX
      this.y = $event.clientY
      this.$nextTick(() => {
        this.displayContextMenu = true
      })
    },

    getComponentDateText(dateData) {

      if ( !this.entity.online ) {

        let date = new Date(this.studentProfile.date)
        date = date.setDate(date.getDate() + dateData)

        return this.PARSE_DATE(date)
      }

      return this.PARSE_DATE(dateData)
    },

    openExtendSubscription() {
      this.displaySubscriptionDialog = true
    },

    async purchaseCourse(number,total) {

      if ( this.GET_ONLINE_PAYMENT === 'online_liqpay') {
        this.getLiqpayButton(number,total)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_mono') {
        this.getMonoUrl(number,total)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_fondy') {
        this.getFondyUrl(number,total)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_wayforpay') {
        this.getWayforpayUrl(number,total)
      }

    },
    async getMonoUrl(number,total) {
      let data = {
        order_number: number,
        total: total,
        return_url: location.href
      }

      let result = await this.GET_MONO_CHECKOUT_URL(data)
      if ( !result ) {
        return
      }
      let a = document.createElement('a')
      a.href = result.pageUrl
      a.click()
      a.remove()

    },
    async getWayforpayUrl(number,total) {
      let data = {
        order_number: number,
        total: total,
        return_url: location.href
      }

      let result = await this.GET_WAYFORPAY_CHECKOUT_URL(data)
      if ( !result ) {
        return
      }
      this.WAYFORPAY_INVOICE(result)

    },
    async getFondyUrl(number,total) {
      let data = {
        order_number: number,
        total: total,
        return_url: location.href
      }

      let result = await this.GET_FONDY_CHECKOUT_URL(data)
      if ( !result ) {
        return
      }
      let a = document.createElement('a')
      a.href = result.url
      a.click()
      a.remove()

    },
    async getLiqpayButton(number,total) {

      let data = {
        order_number: number,
        total: total,
        return_url: location.href
      }
      let button = await this.GET_BUSINESS_BUTTON(data)
      if ( !button || button === true ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.LIQPAY_checkout(button)

    },
    displayEntityCondition(component) {
      if ( !this.coursePermission.is_permitted || !component.is_permitted ) { return false }
      return true
    },
    displayEntity(component , componentIndex, topicIndex, moduleIndex ) {
      let url  = null
      if ( !this.coursePermission.is_permitted || !component.is_permitted ) { return }
      switch(component.entity_type) {
        case 'file'       :  this.openFile(  component , componentIndex, topicIndex, moduleIndex  ); break;
        case 'video'      :  this.openVideo( component , componentIndex, topicIndex, moduleIndex  ); break;
        default : url = 'courses/component/'    + component.uuid;
      }

      let processCompletion = this.PROCESS_COMPONENT_COMPLETION(component)
      if ( processCompletion ) {
        component.passed = processCompletion.passed
        this.entity = processCompletion.entity
      }

      if ( url ) {
        this.$router.push(this.businessDashLink(url))
        return this.businessDashLink(url)
      }
      return false
    },
    async openVideo(component) {

      this.selectedVideoName = component.name

      this.$store.state.videoPlayer.component = component
      this.$store.state.videoPlayer.display = true


      if ( component.video ) {
        if ( component.video.is_embed && component.video.url) {
          let result = await this.EDIT_COMPONENT_RESULT(component.uuid)
          if ( !result ) {
            return
          }
        }
      }

    //   let myMediaSource = new MediaSource();
    // //  let url = URL.createObjectURL(myMediaSource);
    //
    //   // this.videoSource = url
    //   // this.config.url = url
    //
    //   try {
    //     var sourceBuffer = await myMediaSource.addSourceBuffer('video/mp4; codecs="avc1.42E01E, mp4a.40.2"');
    //   } catch (err) {
    //     console.log(myMediaSource)
    //   }
    //
    //   console.log(sourceBuffer)




    },
    async openFile(component ) {


      if ( component.file ) {
        if ( component.file.mime ) {
          if ( component.file.mime.includes('pdf')) {
            this.displayPdf = true
            this.selectedComponent = JSON.parse(JSON.stringify(component))
            return
          }
        }
      }

      // componentIndex, topicIndex, moduleIndex
      let file = component.file
      const force = file.mime.includes('video')
      let data =  {
        file : file.uuid,
        course : this.uuid,
        force : force
      }

      let result = await this.GET_STUDENT_COMPONENT_FILE(data)
      if (!result) { return this.notify(this.$t('FileNotFound')) }

      if(typeof result.url === 'object' && result.url !== null) {
        // Extract first key's value
        var firstKey = Object.keys(result.url)[0];
        result.url = result.url[firstKey];
      }

      if ( this.hasAudio(component.file) ) {
        this.$store.state.audioPlayer.src = result.url
        this.$store.state.audioPlayer.title = component.name
        this.$store.state.audioPlayer.display = true

        return
      }



      var a = document.createElement('a');
      a.href = result.url;
      a.download = file.name;
      a.target = '_self'
      if (result.mime.includes('image')) {
        this.displayImage = true
        this.selectedImage = {
          src : result.url,
          name : component.name
        }
      } else {
        a.download = file.name;
        a.mime = result.mime
        console.log(a)
        document.body.appendChild(a);
        a.click();
        a.remove();
      }

    },



    // Technical
    hasAudio(file) {
      if ( !file ) {
        return false
      }
      if ( !file.mime) {
        return false
      }
      return file.mime.includes('audio')

    },
    getEntityTypeIcon( component ) {
      let type = component.entity_type

      if (this.hasAudio(component.file) ) {
        return 'mdi-volume-high'
      }

      let icon = ''
      switch ( type ) {
        case 'lecture'    :  icon = 'mdi-book-open-page-variant'       ; break;
        case 'task'       :  icon = 'mdi-content-paste'                ; break;
        case 'task_video' :  icon = 'mdi-cast-education'               ; break;
        case 'test'       :  icon = 'mdi-order-bool-ascending-variant' ; break;
        case 'task_test'  :  icon = 'mdi-order-bool-ascending-variant' ; break;
        case 'video'      :  icon = 'mdi-video'                        ; break;
        case 'file'       :  icon = 'mdi-file'                         ; break;
        default : icon = 'mdi-bookmark'
      }
      return icon

    },
    getEntityTypeIconColor( component ) {

      if ( !this.coursePermission.is_permitted || !component.is_permitted ) { return 'grey' }

      let type = component.entity_type

      if ( type === 'task_test' && component.hasTest ) {
        return this.wsATTENTION
      }

      if ( type === 'task_video' ) { type = 'task' }
      if ( type === 'file' ) {
        if ( component.file ) {
          if ( component.file.uuid ) {
            return this.wsATTENTION
          }
        }
      }

      if ( type === 'video' && !component.video && component.file ) {
        return this.wsATTENTION
      }

      return component[type] ? this.wsATTENTION : 'grey'
    },
    scrollToModule(index) {
      this.$vuetify.goTo(this.$refs['module_' + index][0],
          {
            duration: 300,
            offset: 70,
            easing: 'easeInOutQuad',
          })
    },
    // entities
    getEntityLink(component) {
      let url  = null
      if ( !this.coursePermission.is_permitted || !component.is_permitted ) { return false }

      url = 'courses/component/'    + component.uuid ;

      if ( url ) {
        return this.businessDashLink(url)
      }
      return false
    },
    getEntityDisplayCondition(component) {

      if ( !this.coursePermission.is_permitted || !component.is_permitted ) { return false }

      switch(component.entity_type) {
        case 'lecture' :  return !!component.lecture
        case 'task'    :  return !!component.task
        case 'task_test'    :  return !!component.task
        case 'task_video'   :  return !!component.task
        case 'file'   :  return !!component.file
        case 'video' : return !!component.file || !!component.video
        default : return false
      }
    },
    getEntityTypeTitle(component) {
      switch(component.entity_type) {
        case 'lecture' :  return this.$t('Lecture')
        case 'task'    :  return this.$t('Task')
        default : return "none"
      }
    },


    // Page init
    async initPage(){
      this.loading = true
      let result =  await this.GET_STUDENT_COURSE(this.uuid) || {} ;
      if ( !result ) {
        this.loading = false
        return
      }
      this.loading = false

      this.entity = result.course;
      this.$store.state.courses.studentCourse = result.course
      this.studentProfile = result.student_profile
      this.coursePermission = result.permission

      this.courseNotStarted = result.course_not_started
      this.courseNotPurchased = result.course_not_purchased
      this.educationStart = result.education_start
      this.courseCompletion = result.completion ? result.completion.percent : 0

      this.READ_EVENTS({ type : 'new_course_component_published', entity : this.componentId })

      if (result.issue_certificate) {
        this.$store.state.courses.courseCompletionId = this.entity.uuid
        this.$store.state.courses.displayCourseCompletionDialog = true
      }

    }
  },

  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>