<template>
  <wsDialog
      v-model="display"
      :save-text="$t('Buy')"
      @save="purchaseCourse"
      :title="$t('ExtendSubscription')"

  >

    <div  class="my-6" v-if="course.is_subscription">
      <ws-select
          v-model="enrollmentData.quantity"
          :items="timePeriodsSelect"
          :label="$t('SubscriptionPeriod')"
          :placeholder="$t('Period')"
      />
    </div>
    <h4 class="text-right mb-3"><span :style="`color : ${wsACCENT}`">{{ $t('ExtendTill') }}</span> : {{ subscriptionTill }}</h4>

    <v-divider />
    <h4 class="text-right mt-3"> {{ $t('Total') }} {{ totalPriceText }}</h4>

  </wsDialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "subscriptionDialog",
  props : {
    value : {
      type : Boolean,
      default : false
    },
    course : {
      type : Object,
      default() { return {} }
    },
    studentProfile : {
      type : Object,
      default() { return {} }
    },
  },
  data() {
    return {
      display : false,
      enrollmentData : {
        quantity : 1,
        payment_plan_id : null
      }
    }
  },
  computed : {
    subscriptionTill() {

      let currentDate = new Date()
      let date = this.studentProfile.subscription_date ? new Date(this.studentProfile.subscription_date) : currentDate
      date = date < currentDate ? currentDate : date

      if ( this.course.time_period_type === 'month' ) {
        date = new Date( date.setMonth(date.getMonth() + this.enrollmentData.quantity) )
      }
      if ( this.course.time_period_type === 'year' ) {
        date = new Date( date.setFullYear(date.getFullYear() + this.enrollmentData.quantity) )
      }
      if ( this.course.time_period_type === 'custom' ) {
        date = new Date( date.setDate(date.getDate() + (this.enrollmentData.quantity*this.course.time_period_days )) )
      }

      return this.PARSE_DATE(date)

    },
    timePeriodsSelect() {
      if ( !this.course.time_periods_select) {
        return []
      }
      let items = []
      this.course.time_periods_select.forEach((period)=>{
        let type = this.course.time_period_type
        let quantity = type === 'custom' ? (period * this.course.time_period_days ) : period
        type = type === 'custom' ? 'day' : type

        items.push({
          text : this.$tc(type , quantity),
          value : period
        })
      })
      return items
    },
    singlePriceText() {

      let price = this.course.price
      if ( this.course.has_payment_plans) {
        if (this.enrollmentData.payment_plan_id) {
          let course = this.course.payment_plans.find(el => el.value === this.enrollmentData.payment_plan_id)
          price = course.price
        }
      }

      return price + ' ' + this.BUSINESS_CURRENCY

    },
    totalPriceText() {
      return this.totalPrice + ' ' + this.BUSINESS_CURRENCY
    },
    priceDescriptionSubscriptionText() {
      if ( !this.course.is_subscription ) {
        return ''
      }

      if ( this.course.subscription_type === 'time_period' ) {
        if ( this.course.time_period_type === 'month' ) {
          return ' ' + this.$tc('perMonths' , 1)
        }
        if ( this.course.time_period_type === 'year' ) {
          return ' ' + this.$tc('perYears' , 1)
        }
        if ( this.course.time_period_type === 'custom' ) {
          return ' ' + this.$tc('perDays' , this.course.time_period_days)
        }
      }

      return ''

    },
    totalPrice() {

      let price = this.course.price
      if ( this.course.has_payment_plans) {
        if ( this.studentProfile.payment_plan_id ) {
          let course = this.course.payment_plans.find(el => el.value === this.studentProfile.payment_plan_id)
          price = course.price
        }
      }

      if ( this.enrollmentData.quantity && price ) {
        price = price * this.enrollmentData.quantity
      }

      return price

    }
  },
  watch : {
    value() {
      if ( this.display !== this.value ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.display !== this.value ) {
        this.$emit('input' , this.display)
      }
    }
  },
  methods : {

    ...mapActions('courses', [  'GET_COURSE_PUBLIC' , 'CHECK_STUDENT_ENROLLMENT', 'GET_COURSE_FULL', 'ENROLL_ON_COURSE' , 'GET_ENROLLMENT_WAVES_STUDENT' ]),
    ...mapActions('cart', [  'ORDER_COURSE'  ]),
    ...mapActions('liqpay', ['GET_BUSINESS_BUTTON']),
    ...mapActions('monobank', ['GET_MONO_CHECKOUT_URL']),
    ...mapActions('fondy', ['GET_FONDY_CHECKOUT_URL']),
    ...mapActions('wayforpay', ['GET_WAYFORPAY_CHECKOUT_URL']),
    ...mapActions('stripe', ['GET_STRIPE_CHECKOUT_URL']),
    ...mapActions('portmone', ['GET_PORTMONE_CHECKOUT_URL']),

    async purchaseCourse() {

      if (!this.enrollmentData.quantity) {
        this.notify(this.$t('ChooseSubscriptionPeriod') , 'warning')
        return
      }

      let data = {
        course_id : this.course.uuid,
        origin : this.DEVICE
      }

      if (  this.course.online && this.course.scheduled ) {
        data.enrollment_wave_id = this.studentProfile.enrollment_wave_id
      }

      if (  this.course.payed && this.course.has_payment_plans ) {
        data.payment_plan_id = this.studentProfile.payment_plan_id
      }

      if ( this.enrollmentData.quantity ) {
        data.quantity = this.enrollmentData.quantity
      }

      let result = await this.ORDER_COURSE(data)
      if ( !result ) { return this.notify( this.$t('NetworkError') ) }

      this.course.enrolled = true

      this.processOnlinePayment(result)


    },
    async processOnlinePayment(order) {
      if ( !this.HAS_ONLINE_PAYMENT ) {
        this.display = false
        this.notify(this.$t('OrderCreated') , 'success')
        return
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_liqpay') {
        this.getLiqpayButton(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_mono') {
        this.getMonoUrl(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_fondy') {
        this.getFondyUrl(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_wayforpay') {
        this.getWayforpayUrl(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_stripe') {
        this.getStripeUrl(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_portmone') {
        this.getPortmoneUrl(order)
      }
    },

    async getMonoUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: location.href
      }

      let result = await this.GET_MONO_CHECKOUT_URL(data)
      if ( !result ) {
        return
      }
      let a = document.createElement('a')
      a.href = result.pageUrl
      a.click()
      a.remove()

    },
    async getWayforpayUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: location.href
      }

      let result = await this.GET_WAYFORPAY_CHECKOUT_URL(data)
      if ( !result ) {
        return
      }
      this.WAYFORPAY_INVOICE(result)
      this.displayEnroll = false
      this.displayEnrollSuccess = true

    },
    async getFondyUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: location.href
      }

      let result = await this.GET_FONDY_CHECKOUT_URL(data)
      if ( !result ) {
        return
      }
      let a = document.createElement('a')
      a.href = result.url
      a.click()
      a.remove()

    },
    async getLiqpayButton(order) {

      let data = {
        order_number: order.order_number,
        total: this.totalPrice,
        return_url: location.href
      }
      let button = await this.GET_BUSINESS_BUTTON(data)
      if ( !button || button === true ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.LIQPAY_checkout(button)
      this.displayEnroll = false
      this.displayEnrollSuccess = true

    },
    async getStripeUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: this.businessDashLink('',false, true)
      }

      let result = await this.GET_STRIPE_CHECKOUT_URL(data,'stripe')
      if ( !result ) {
        return
      }
      let a = document.createElement('a')
      a.href = result.url
      a.click()
      a.remove()

    },
    async getPortmoneUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: this.businessDashLink('',false, true)
      }

      let result = await this.GET_PORTMONE_CHECKOUT_URL(data,'portmone')
      if ( !result ) {
        return
      }
      let a = document.createElement('a')
      a.href = result.url
      a.click()
      a.remove()

    },

  },
  mounted() {
    this.display = this.value
    this.enrollmentData.payment_plan_id = this.studentProfile.payment_plan_id
    this.enrollmentData = Object.assign({} , this.enrollmentData)
  }
}
</script>

<style scoped>

</style>